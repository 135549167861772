import request from '@/http.js'

/**
 * @description 积分管理 -- 积分日志
 * @param {Object} param data {Object} 传值参数
 */
export function integralLogListApi (param) {
    return request.post(
        '/admin.user/integral',
        param
    )
}