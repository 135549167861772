<!--积分日志-->
<template>
    <div class="mainBox">
        <!--场景搜索-->
        <div class="selectionBar">
            <div class="flex flex-ai-c flex-wrap">
                <label>场景：</label>
                <el-input placeholder="请输入姓名/昵称/手机号/会员卡号" v-model="select.keywords" size="small" style="width:300px;margin-left:10px" @change="curr=1;getList()">
                    <el-button slot="append" icon="el-icon-search" style="background：#fff"></el-button>
                </el-input>
                <el-date-picker v-model="select.create_time" style="width:300px;margin-left:10px" @change="curr=1;getList()" type="daterange" range-separator="至" start-placeholder="创建时间" end-placeholder="筛选" value-format="yyyy-MM-dd">
                </el-date-picker>
            </div>
        </div>
        <!--积分统计-->
        <div class="handleLogBox">
            <el-row :gutter="20" type="flex" justify="space-between">
                <el-col :span="5">
                    <el-card shadow="always">
                        <el-row :gutter="12" type="flex" align="middle">
                            <el-col :span="11" style="background: #e4ecff" class="card-icon one flex flex-jc-c flex-ai-c">
                                <div style="background: #4d7cfe;" class="icon-style flex flex-jc-c flex-ai-c">
                                    <i class="el-icon-s-help"></i>
                                </div>
                            </el-col>
                            <el-col :span="13">
                                <p class="log_num">{{listCount.integral}}</p>
                                <p class="log_text">总积分</p>
                            </el-col>
                        </el-row>
                    </el-card>
                </el-col>
                <el-col :span="5">
                    <el-card shadow="always">
                        <el-row :gutter="12" type="flex" align="middle">
                            <el-col :span="11" style="background: #fff3e0" class="card-icon one flex flex-jc-c flex-ai-c">
                                <div style="background: #ffab2b;" class="icon-style flex flex-jc-c flex-ai-c">
                                    <i class="el-icon-sell"></i>
                                </div>
                            </el-col>
                            <el-col :span="13">
                                <p class="log_num">{{listCount.integral_order}}</p>
                                <p class="log_text">购买返积分</p>
                            </el-col>
                        </el-row>
                    </el-card>
                </el-col>
                <el-col :span="5">
                    <el-card shadow="always">
                        <el-row :gutter="12" type="flex" align="middle">
                            <el-col :span="11" style="background: #f9eef3" class="card-icon one flex flex-jc-c flex-ai-c">
                                <div style="background: #ff85c0;" class="icon-style flex flex-jc-c flex-ai-c">
                                    <i class="el-icon-chat-square"></i>
                                </div>
                            </el-col>
                            <el-col :span="13">
                                <p class="log_num">{{listCount.integral_evaluate_good}}</p>
                                <p class="log_text">好评返积分</p>
                            </el-col>
                        </el-row>
                    </el-card>
                </el-col>
                <!-- <el-col :span="4">
                    <el-card shadow="always">
                        <el-row :gutter="12" type="flex" align="middle">
                            <el-col :span="11" style="background: #f2ebfb" class="card-icon one flex flex-jc-c flex-ai-c">
                                <div style="background: #b37feb;" class="icon-style flex flex-jc-c flex-ai-c">
                                    <i class="el-icon-sold-out"></i>
                                </div>
                            </el-col>
                            <el-col :span="13">
                                <p class="log_num">{{listCount.integral_order_pay}}</p>
                                <p class="log_text">已使用积分抵商品</p>
                            </el-col>
                        </el-row>
                    </el-card>
                </el-col> -->
                <el-col :span="5">
                    <el-card shadow="always">
                        <el-row :gutter="12" type="flex" align="middle">
                            <el-col :span="11" style="background: #e2f7d5" class="card-icon one flex flex-jc-c flex-ai-c">
                                <div style="background: #95de64;" class="icon-style flex flex-jc-c flex-ai-c">
                                    <i class="el-icon-thumb"></i>
                                </div>
                            </el-col>
                            <el-col :span="13">
                                <p class="log_num">{{listCount.integral_order_pay}}</p>
                                <p class="log_text">已使用积分付款</p>
                            </el-col>
                        </el-row>
                    </el-card>
                </el-col>
                <el-col :span="5">
                    <el-card shadow="always">
                        <el-row :gutter="12" type="flex" align="middle">
                            <el-col :span="11" style="background: #ddf7f5" class="card-icon one flex flex-jc-c flex-ai-c">
                                <div style="background: #5cdbd3;" class="icon-style flex flex-jc-c flex-ai-c">
                                    <i class="el-icon-coin"></i>
                                </div>
                            </el-col>
                            <el-col :span="13">
                                <p class="log_num">{{listCount.integral_sum_use}}</p>
                                <p class="log_text">使用积分总数</p>
                            </el-col>
                        </el-row>
                    </el-card>
                </el-col>
            </el-row>
        </div>

        <!--主要内容-->
        <div class="table" style="height:calc( 100% - 287px )">
            <el-table :data="list"  stripe :header-cell-style="{background:'#fafdff',color:'#606266',fontWeight:'normal'}" @sort-change='getList()' height="calc( 100% - 41px )">
                <!-- <el-table-column prop="id" label="ID" align="center"  width="50" show-overflow-tooltip></el-table-column> -->
                <el-table-column prop="realname" label="姓名" show-overflow-tooltip></el-table-column>
                <el-table-column prop="mobile" label="手机号" show-overflow-tooltip></el-table-column>
                <el-table-column prop="card" label="会员卡" width="150" show-overflow-tooltip></el-table-column>
                <el-table-column label="头像" width="80">
                    <template slot-scope="scope">
                        <el-image v-if="scope.row.headimgurl" :src="scope.row.headimgurl" :preview-src-list="[scope.row.headimgurl]" fit="cover" class="tableImage"></el-image>
                        <el-avatar v-else shape="square" :size="40" :src="require('../../assets/ava.png')"></el-avatar>
                    </template>
                </el-table-column>
                <el-table-column prop="nickname" label="昵称" show-overflow-tooltip></el-table-column>
                <el-table-column prop="integral_order" label="购买返积分" show-overflow-tooltip></el-table-column>
                <el-table-column prop="integral_evaluate_good" label="好评返积分" show-overflow-tooltip></el-table-column>
                <el-table-column prop="integral_evaluate_middle" label="中评返积分" show-overflow-tooltip></el-table-column>
                <el-table-column prop="integral_evaluate_bad" label="差评返积分" show-overflow-tooltip></el-table-column>

                <!-- <el-table-column prop="integral_evaluate_good" label="评论返积分" align="center" show-overflow-tooltip></el-table-column>
                <el-table-column prop="integral_order_pay_count" label="积分抵商品次数" align="center" show-overflow-tooltip></el-table-column>
                <el-table-column prop="integral_order_pay" label="使用积分抵商品" align="center" show-overflow-tooltip></el-table-column>
                <el-table-column prop="integral_order_pay" label="已使用积分付款" align="center" show-overflow-tooltip></el-table-column> -->
                <el-table-column prop="integral_sum_use" label="使用总积分" show-overflow-tooltip></el-table-column>
                 <el-table-column prop="integral" label="未使用积分" show-overflow-tooltip></el-table-column>
                <el-table-column prop="create_time" width="180" label="创建时间" show-overflow-tooltip></el-table-column>
            </el-table>
            <!-- 分页 -->
            <div class="paging">
                <el-pagination @current-change="currentChange" @size-change="sizeChange" :current-page="curr" :page-size="row" background layout="total, sizes, prev, pager, next, jumper" :total="count"></el-pagination>
            </div>
        </div>
    </div>
</template>
<script>
    import {integralLogListApi}  from '@/api/integral.js';
    export default {
        data() {
            return {
                select:{},
                list:[], //商品列表
                listCount:{},

                curr: 1, //页码
                row: 10, //每页条数
                pages: 1, //总页数
                count: 0, //总条数
            }
        },
        mounted() {
            this.getList();
        },
        methods: {
            getList: function(){
                integralLogListApi({
                    curr:this.curr,
                    row:this.row,
                    ...this.select
                }).then(response=>{
                    this.list = response.list;
                    this.listCount = response.list_count;
                    this.curr = response.curr;
                    this.pages = response.pages;
                    this.count = response.count;
                })
            },
            //翻页
            currentChange: function(curr) {
                this.curr = curr;
                this.getList();
            },
            //改变每页条数
            sizeChange: function(row) {
                this.row = row;
                this.getList();
            },
        },
    }
</script>